
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        



















































.culture-team {
  background: linear-gradient(
    180deg,
    rgba($c-white, 1) 0%,
    rgba($c-white, 1) 25%,
    rgba($c-gray-lightest, 1) 25%,
    rgba($c-gray-lightest, 1) 100%
  );

  @include mq(l) {
    background: linear-gradient(
      180deg,
      rgba($c-white, 1) 0%,
      rgba($c-white, 1) 40%,
      rgba($c-gray-lightest, 1) 40%,
      rgba($c-gray-lightest, 1) 100%
    );
  }
}

.culture-team__intro {
  &::v-deep .intro {
    background-color: transparent;
  }
}

.culture-team__picture {
  width: 100%;
  max-width: 100rem;
  height: calc(100vw / 2);
  max-height: 50rem;
  margin: 0 auto;
}

.culture-team__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $spacing * 5 0 0;
}

.culture-team__item {
  display: flex;
  max-width: 50rem;
  margin-bottom: $spacing * 2;

  @include mq(l) {
    flex-basis: 45%;
    margin-bottom: $spacing * 5;
  }
}

.culture-team__item__icon {
  flex-shrink: 0;
  width: 16rem;
  height: 16rem;
}

.culture-team__item__content {
  margin-left: $spacing;

  @include mq(l) {
    margin-left: $spacing * 2;
  }
}

.culture-team__item__content__title {
  margin-top: 0.3em;
}

.culture-team__item__content__text {
  margin-top: 1.5em;
  font-size: 1.4rem;
  line-height: 1.5;

  @include mq(l) {
    font-size: 1.8rem;
  }
}

.culture-team__item__content__link {
  margin-top: $spacing * 2;
}

.culture-team__btn-outer {
  display: flex;
  justify-content: center;
}

.culture-team__btn {
  display: flex;
  margin-bottom: $spacing;

  @include mq(m) {
    margin-bottom: $spacing * 5;
  }
}
