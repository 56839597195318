
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        




















.missions__duos {
  background-color: $c-white;
}

.missions__duos-inner {
  margin-top: $spacing * 2;

  @include mq(l) {
    margin-top: $spacing * 5;
  }
}

.missions__duo {
  & + & {
    margin-top: $spacing * 2;

    @include mq(l) {
      margin-top: $spacing * 5;
    }
  }
}
