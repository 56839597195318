
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        






































.members-grid {
  background-color: $c-white;
}

.members-grid__highlight {
  display: flex;
  border-top: 1px solid $c-blue-medium;
  border-bottom: 1px solid $c-blue-medium;

  @include mq(m) {
    margin-right: $spacing * 3;
    margin-left: $spacing * 3;
  }

  @include mq(xl) {
    margin-right: $spacing * 6;
    margin-left: $spacing * 6;
  }

  @include mq(xxl) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.members-grid__highlight__item {
  flex-basis: 50%;
  padding: $spacing * 1.5 $spacing;

  @include mq(m) {
    padding: $spacing * 2 $spacing * 3;
  }

  @include mq(l) {
    padding: $spacing * 3 $spacing * 5;
  }

  & + & {
    border-left: 1px solid $c-blue-medium;
  }
}

.members-grid__items-outer {
  @include mq(m) {
    margin-right: $spacing * 3;
    margin-left: $spacing * 3;
  }

  @include mq(xl) {
    margin-right: $spacing * 6;
    margin-left: $spacing * 6;
  }

  @include mq(xxl) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.members-grid__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -$spacing * 1.5;
  padding: $spacing * 1.5 $spacing * 2;

  .members-grid__item {
    width: 13rem;
    margin: $spacing * 2 $spacing * 1.5;
  }

  @include mq($until: m) {
    justify-content: space-between;
  }

  @include mq(m) {
    justify-content: center;
    margin: 0 -$spacing * 2;
    padding: $spacing * 2 $spacing * 3;

    .members-grid__item {
      width: 25rem;
      margin: $spacing * 3 $spacing * 2;
    }
  }

  @include mq(l) {
    margin: 0 -3vw;
    padding: $spacing * 3 $spacing * 5;

    .members-grid__item {
      width: calc(82% / 3);
      margin: $spacing * 3 3%;
    }
  }
}
