
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        









































.presse__collapses {
  padding-bottom: $spacing * 2;
  background-color: $c-white;

  .collapses__intro {
    background-color: $c-white !important;
  }

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}

.presse__infos-outer {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  background-color: $c-white;

  .contact-infos__content__adress {
    width: 100% !important;
  }

  @include mq(l) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}

.presse__infos {
  max-width: 100rem;
  margin: 0 auto;
  padding: $spacing * 2;
  padding-right: 0;
  background-color: $c-gray-lightest;

  .presse-infos__picture {
    width: 30rem !important;
    height: 30rem !important;
  }

  .presse-infos__content {
    padding-top: $spacing * 1.5 !important;
    padding-bottom: $spacing * 1.5 !important;
  }

  .presse-infos__content,
  .presse-infos__picture {
    flex-basis: auto !important;
  }

  @include mq(l) {
    padding: $spacing * 5;
    padding-right: 0;
  }
}

.presse__styleguide {
  background-color: $c-white;
}

.presse__styleguide__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-bottom: $spacing * 5;
  }

  @include mq(xl) {
    padding-bottom: $spacing * 8;
  }
}

.presse__styleguide__item {
  width: 100%;
  margin: 2.5% 0;

  @include mq(m) {
    width: 45%;
    margin: 1.25%;
  }

  @include mq(xl) {
    width: auto;
  }
}

.presse__styleguide__item__picture {
  height: 25rem !important;
  border-top: 1px solid $c-blue-medium !important;
  border-right: 1px solid $c-blue-medium !important;
  border-left: 1px solid $c-blue-medium !important;
}

.presse__styleguide__item__btn {
  // width: 100%;

  .btn__label {
    width: 100% !important;
  }
}
