
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        













.profiles {
  ::v-deep {
    .intro-inner {
      padding: $spacing * 2 0 $spacing * 3;
    }
  }
}
