
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        















































.profiles-item__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40rem;
  min-height: 20rem;
  background-color: $c-gray-lightest;

  @include mq(m) {
    flex-basis: 50%;
    /* stylelint-disable-next-line declaration-no-important */
    height: 57rem !important;
  }
}

.profiles-item {
  text-decoration: none;

  &:hover {
    .profiles-item__picture {
      &::v-deep .picture-inner {
        transform: scale(1.06);
      }
    }
  }
}

.profiles-item__picture-inner {
  @include image-fit;

  object-position: center 20%;
  transition: transform 0.2s ease-out;
}

.profiles-item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing;
}

.profiles-item__job-outer {
  margin-top: $spacing;
}

.profiles-item__job,
[class*='profiles-item__job--'] {
  color: $c-black;
  font-size: 1.4rem;

  &[class*='--big'] {
    font-size: 1.8rem;
  }
}

.profiles-item__function {
  margin-top: $spacing / 4;
  padding-right: 2rem;
  color: $c-gray-light;
}
