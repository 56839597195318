
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

























.jobs__video {
  margin-bottom: -4rem;

  @include mq(m) {
    margin-bottom: -7rem;
  }

  @include mq(xl) {
    margin-bottom: -10rem;
  }
}

.jobs__duos {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  background-color: $c-white;

  @include mq(l) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}

.jobs__duos-inner {
  margin-top: $spacing * 2;

  @include mq(l) {
    margin-top: $spacing * 5;
  }
}

.no-margin {
  ::v-deep {
    .intro-inner {
      padding: 0;
    }
  }
}

.jobs__duo {
  & + & {
    margin-top: $spacing * 2;

    @include mq(l) {
      margin-top: $spacing * 5;
    }
  }
}

.jobs__collapses {
  padding-bottom: $spacing * 2;
  background-color: $c-gray-lightest;

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}
