
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        































.profiles-list {
  padding-top: $spacing * 2;
  background-color: $c-white;
}

.profiles-list__items-outer {
  @include mq(m) {
    margin-right: $spacing * 3;
    margin-left: $spacing * 3;
  }

  @include mq(xl) {
    margin-right: $spacing * 6;
    margin-left: $spacing * 6;
  }

  @include mq(xxl) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.profiles-list__title {
  .is-profiles & {
    display: none;
  }
}

.profiles-list__filter-outer {
  position: relative;
  z-index: 9;
  max-width: 80rem;
  padding: 0 0 $spacing * 2;

  @include mq(m) {
    margin: 0 auto;
  }
}

.profiles-list__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -$spacing * 1.5;
  padding: $spacing * 1.5 0;

  .profiles-list__item {
    width: 100%;
    margin: $spacing * 2;
  }

  @include mq($until: m) {
    justify-content: space-between;
  }

  @include mq(s) {
    margin: 0 -1%;
    padding: $spacing * 2 0;

    .profiles-list__item {
      width: calc(92% / 2);
      margin: $spacing * 3 2%;
    }
  }

  @include mq(l) {
    margin: 0 -1.2vw;
    padding: 0;

    .profiles-list__item {
      width: calc(94% / 3);
      margin: $spacing * 3 1%;
    }
  }
}
