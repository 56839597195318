
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        


































.default-template {
  margin-top: $header-height;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  background-color: $c-white;

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;
  }

  @include mq($header-breakpoint) {
    margin-top: $header-height-large;
  }

  @include mq(xl) {
    padding-top: $spacing * 8;
    padding-bottom: $spacing * 8;
  }
}

.default-template-inner {
  position: relative;
  overflow: hidden;
  max-width: 144rem;
  margin: 0 auto;
  padding-right: $spacing * 2;
  padding-left: $spacing * 2;

  @include mq(m) {
    padding-right: $spacing * 3;
    padding-left: $spacing * 3;
  }

  @include mq(xl) {
    padding-right: $spacing * 16;
    padding-left: $spacing * 16;
  }

  @include mq(xxxl) {
    width: 100%;
    padding-right: auto;
    padding-left: auto;
  }
}

.default-template__title {
  max-width: 70rem;
}

.default-template__lead {
  max-width: 80rem;
  margin-top: $spacing * 1.5;
}

.default-template__wysiwyg {
  margin: $spacing * 2 0;

  p {
    & + p {
      margin-top: $spacing * 2;
    }
  }

  @include mq(m) {
    margin: $spacing * 3;
  }

  @include mq(xl) {
    margin: $spacing * 4;
  }
}

.default-template__author {
  border-top: 1px solid $c-gray-light;
}

.wp-playlist {
  video {
    width: 100%;
  }
}
