
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        




























































































































































































































































































































.profiles-slider {
  position: relative;
  padding-bottom: $spacing;
  background-color: $c-white;

  @include mq(m) {
    padding-bottom: $spacing * 3;
  }

  @include mq(xl) {
    padding-bottom: $spacing * 6;
  }
}

.profiles-slider__items {
  position: relative;
  margin-top: $spacing * 2;
}

.profiles-slider__item {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }

  &:nth-child(even) {
    @include mq(m) {
      .profiles-slider__item__misc {
        grid-row: 3/ 5;
        grid-column: 1/ 3;
      }

      .profiles-slider__item__picture {
        position: relative;
        grid-row: 3/ 5;
        grid-column: 3/ 5;
        overflow: hidden;
      }

      .profiles-slider__item__picture--horizontal {
        position: relative;
        grid-row: 1/ 3;
        grid-column: 1/ 5;
        overflow: hidden;
      }
    }
  }

  @include mq($until: m) {
    flex-direction: column;
  }

  @include mq(m) {
    width: 100%;
    // height: 60rem;
  }
}

.slidy__item {
  height: 100%;
  // background-color: $c-white;
}

.profiles-slider__item__content-outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  padding: $spacing * 3 $spacing * 2 $spacing * 2;
  color: $c-white;
  background-color: $c-blue-medium;

  @include mq(m) {
    width: 50%;
    padding: $spacing * 8 $spacing * 5 $spacing * 2;
  }
}

.profiles-slider__item__content {
  position: relative;
  color: $c-white;
}

.profiles-slider__item__misc-outer {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  flex-grow: 1;
  flex-shrink: 0;

  @include mq(m) {
    width: 50%;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.profiles-slider__item__misc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  grid-row: 1/ 3;
  grid-column: 1/ 3;
  padding: $spacing * 2;
  background-color: $c-gray-lightest;
}

.profiles-slider__item__misc__title {
  position: relative;

  @include mq(l) {
    font-size: 3.2rem;
  }
}

.profiles-slider__item__misc__title--uppercase {
  @extend %text-uppercase;
}

.profiles-slider__item__misc__function {
  position: relative;
  margin-top: $spacing * 0.5;
  color: $c-gray;
}

/* stylelint-disable-next-line no-descending-specificity */
.profiles-slider__item__picture,
[class*='profiles-slider__item__picture--'] {
  z-index: 2;
  grid-row: 1/ 3;
  grid-column: 3/ 5;
  flex-shrink: 0;
  min-height: 15rem;
  background-color: transparent;

  &[class*='--horizontal'] {
    grid-row: 3/ 5;
    grid-column: 1/ 5;
  }

  ::v-deep {
    .picture-inner {
      @include image-fit(cover);

      object-position: 10% 15%;
    }
  }
}

.profiles-slider__item__content__icon {
  position: absolute;
  top: 0;
  left: 0;
  fill: $c-blue-dark;
  transform: translateY(-100%);
}

::v-deep {
  .slidy-outer {
    position: relative;
  }
}

.slider-controls {
  display: flex;
  margin-top: $spacing * 2;
}

.slider-control__prev,
.slider-control__next {
  @extend %button-nostyle;

  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 6.6rem;
  height: 6.6rem;
  background-color: transparent;
  border: 1px solid $c-white;
  fill: $c-white;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 30vw;
    height: 30vw;
    background-color: $c-blue-dark;
    transform: rotate(-45deg) translateY(-150%) translateX(-20%);
    transform-origin: top;
    transition: transform 0.5s ease-out;
  }

  @include mq(l) {
    &:hover {
      fill: $c-white;

      &::after {
        transform: rotate(-45deg) translateY(-60%) translateX(-20%);
      }
    }
  }

  @include mq($until: l) {
    margin-top: $spacing * 2;
  }
}

.slider-control__next {
  margin-left: -1px;
  fill: $c-blue-medium;

  &::before {
    background-color: $c-white;
  }
}

.slider-control__prev__icon,
.slider-control__next__icon {
  @include center-xy;
}

.slider-control__prev__icon {
  transform: translate(-50%, -50%) rotate(180deg);
}

.profiles-slider__btn-outer {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.profiles-slider__btn {
  display: flex;
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 4;
  }
}
