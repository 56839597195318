
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

























.contact__items {
  background-color: $c-white;
}

.contact__items-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: $spacing * 2;

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}

.contact__item {
  @include mq(m) {
    flex-basis: 32%;
  }
}

.contact__infos-outer {
  background-color: $c-gray-lightest;
}

.contact__infos {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;

  @include mq(l) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}
