
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        











.fade-enter-active,
.fade-leave-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
