
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        









.culture__action {
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-bottom: $spacing * 4;
  }

  @include mq(xl) {
    padding-bottom: $spacing * 6;
  }
}
