
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        







































.select {
  position: relative;
  padding: $spacing * 1.5 $spacing * 2;
  border: 0.1rem solid $c-blue-dark;
  border-radius: 1rem;

  &.has-error {
    // border-bottom: 0.1rem solid $c-dev-error;
  }

  li {
    list-style: none;
  }
}

.real-select {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.label {
  position: absolute;
  top: 0;
  display: block;
  padding: $spacing;
  color: $c-blue-light;
  font-weight: 700;
  text-decoration: none;
  background-color: $c-white;
  transform: translate(-2rem, -50%);

  .has-error & {
    color: $c-dev-error;
  }
}

.placeholder {
  /* stylelint-disable declaration-no-important */
  @extend %fw-bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  color: $c-blue-dark !important;
  font-size: 2.4rem !important;
  line-height: 1.4;
  text-decoration: none;
  letter-spacing: -1px;

  strong {
    @extend %text-uppercase;
  }

  &:hover {
    color: $c-gray-light !important;
  }
}

.options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  height: auto;
  padding: 1rem 2rem;
  background: $c-white;
  border: 1px solid $c-black;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;

  .is-open & {
    opacity: 1;
    visibility: visible;
  }
}

.option {
  @extend %fw-bold;

  position: relative;
  display: flex;
  margin: $spacing;
  padding: 0.5rem 0 0.3rem;
  color: $c-blue-medium;
  font-size: 1.4rem;
  line-height: 1.2;
  text-decoration: none;
  letter-spacing: 0.8px;

  &:hover {
    color: $c-blue-light;
  }
}

.icon {
  width: 4rem;
  height: 4rem;
  transition: transform 0.2s;

  .is-open & {
    transform: scale(-1);
  }
}
