/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: rgb(32, 32, 32);
$c-gray-darker: rgb(64, 64, 64);
$c-gray-dark: rgb(96, 96, 96);
$c-gray-medium: rgb(111, 113, 117); // Used
$c-gray: rgb(160, 160, 160);
$c-gray-light: rgb(160, 160, 160);
$c-gray-lighter: rgb(192, 197, 200); // Used
$c-gray-lightest: rgb(240, 244, 246); // Used
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-blue-light: #66cece;
$c-blue-medium: #317af9;
$c-blue-dark: #1D3C8A;
$c-orange: #eb611a;

$c-main-orange: $c-orange;
$c-main-border: #5e5e5e;
$c-footer-bg: #141414;

/*
* Dimensions
*/
$content-width: 1185px;
$spacing: 20px;
$header-height: 80px;
$header-height-large: 120px;
$header-breakpoint: l;


/*
* Typography
*/

$base-font-size: 10px !default;

$ff-default: 'sofia-pro', sans-serif;
$ff-alt: 'museo-sans-rounded', sans-serif;
$body-text-s: 1.5em;
$body-text-m: 1.6em;
$body-text-l: 1.8em;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

