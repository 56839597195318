
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        










.organisation__collapses {
  padding-bottom: $spacing * 2;
  background-color: $c-gray-lightest;

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}

.organisation__team {
  &::v-deep.culture-team {
    background: $c-white;
  }

  &::v-deep .culture-team__items {
    padding-top: 0;
  }
}
