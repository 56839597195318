
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        





























.gouvernance__duo-outer {
  padding-bottom: $spacing * 2;

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}

.gouvernance__payroll,
.gouvernance__audit {
  &::v-deep .intro-inner {
    /* stylelint-disable-next-line declaration-no-important */
    padding-bottom: 0 !important;
  }

  &::v-deep .members-grid__items {
    /* stylelint-disable-next-line declaration-no-important */
    padding-top: 0 !important;
    /* stylelint-disable-next-line declaration-no-important */
    padding-bottom: 0 !important;
  }
}
