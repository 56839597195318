
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        

















.adn-dw__duos {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  background-color: $c-gray-lightest;

  @include mq(l) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}

.adn-dw__duos-inner {
  margin-top: $spacing * 2;

  @include mq(l) {
    margin-top: $spacing * 5;
  }
}

.adn-dw__duo {
  & + & {
    margin-top: $spacing * 2;

    @include mq(l) {
      margin-top: $spacing * 5;
    }
  }
}
