
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        







































.action-slidy {
  position: relative;
  padding-bottom: 4rem;

  .slidy-nav {
    position: absolute;
    top: calc(100vw / 2);
    display: flex;
    width: 100%;
    max-width: 40rem;
    margin-right: 10rem;

    @include mq(l) {
      top: 0;
      bottom: initial;
      left: 60rem;
      margin-top: $spacing / 2;
      margin-left: 5rem;
    }

    @include mq(xl) {
      margin-left: 10rem;
    }
  }

  .slidy-nav__item {
    & + .slidy-nav__item {
      margin-left: 1rem;
    }

    button {
      @extend %fw-bold;
      @extend %button-nostyle;

      color: $c-gray-lighter;
      font-size: 1.6rem;
    }

    &.is-active {
      button {
        color: $c-blue-light;
      }
    }
  }
}

.action-slidy__progress {
  position: absolute;
  top: calc(100vw / 2);
  width: 100%;
  max-width: 40rem;
  margin-top: 0.5px;
  margin-right: 10rem;

  @include mq(l) {
    top: 0;
    bottom: initial;
    left: 60rem;
    margin-top: 0;
    margin-left: 5rem;
  }

  @include mq(xl) {
    margin-left: 10rem;
  }
}

.action-slidy__progress__bar {
  z-index: 10;
  width: 80%;
  height: 0.2rem;
  background-image: linear-gradient(
    to left,
    $c-blue-light 50%,
    $c-gray-lightest 50%,
    $c-gray-lightest 100%
  );
  background-size: 200%;
}

.action-slidy__item {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }

  @include mq(l) {
    flex-direction: row;
    align-items: center;
  }
}

.action-slidy__item__picture {
  /* stylelint-disable declaration-no-important */
  flex-shrink: 0;
  width: 100% !important;
  height: calc(100vw / 2) !important;

  @include mq(l) {
    width: 60rem !important;
    height: 60rem !important;
  }
  /* stylelint-enable declaration-no-important */
}

.action-slidy__item__content {
  max-width: 40rem;

  @include mq($until: l) {
    margin-top: $spacing * 2.5;
  }

  @include mq(l) {
    margin-left: $spacing * 2.5;
  }

  @include mq(xl) {
    margin-left: $spacing * 5;
  }
}

.action-slidy__item__title {
  width: 80%;
}

.action-slidy__item__text {
  margin-top: 1.5em;
  font-size: 1.4rem;
  line-height: 1.8;
}

.action-slidy__item__link {
  margin-top: $spacing * 3;
}
