
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        
























.members-item {
  display: flex;
  flex-direction: column;
}

.members-item__title {
  margin-top: $spacing / 2;

  .is-highlight & {
    color: $c-blue-dark;
    font-size: 2.4rem;
    line-height: 1.4;
    letter-spacing: -1px;

    strong {
      display: block;
    }

    @include mq(l) {
      font-size: 2.8rem;
    }

    @include mq(xxl) {
      font-size: 3.6rem;
    }
  }

  @include mq($until: m) {
    strong {
      strong {
        display: block;
      }
    }
  }
}

.members-item__function {
  margin: $spacing / 2 0 $spacing;
}

.members-item__function__social {
  margin-top: auto;
}
