
          $env: 'production';
          @import '/builds/adn/corporate.v1/app/src/styles/utils/index';
        











.contact-item__title {
  margin-top: $spacing / 2;
}

.contact-item__text {
  margin-top: $spacing / 2;
}
